import React from 'react';
import Image from '../../components/image';
import BasicPortlet from '../../components/basicPortlet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChartBody from '../../components/chartBody';
import Card from 'react-bootstrap/Card';
import { getApi, attributeProfileFilterSubmit } from "../../utils/event_handling";
import Load from '../../components/loadAction';
import EmptyAPIResponse from '../../components/emptyResponse';
import { getChartFilterData, filterBoxPlotData, sortDict } from "../../utils/common_utils";
// import Filter from '../components/attributeFilter';
import {
    ENVIRONMENT,
    DATA_SOURCE,
    DATA_SET,
    ATTRIBUTE,
    MIN_MAX_SCALE,
    BOX_PLOT_METRICS,
    NON_NUMERIC_COLUMNS,
    IS_NONE_CHECK,
    DATA_TYPE_MAPPING,
    NO_DATASET_NO_ERROR
    // CONFIGURE_DATA_SOURCE_URL
} from '../../utils/constant';
import { faChartArea, faFolderOpen, faCloud } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import { setDataSource, setDataSet, setAttribute } from "../../redux/actions";
// import { FilterOptions } from '../components/attributeFilterOptions';
import NoErrorComponent from '../../monitor/components/monitorNoResult';
import ChartFilterHeader from '../../monitor/components/chartFilterHeader';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import NoDataComponent from "../components/noDataComponent"
import CardHelpContent from '../../components/cardHelpContent';
import ProgressStatus from '../../components/progressStatus';
import RcTooltip from 'rc-tooltip';
import {
    // parseMetaMapping,
    convertDecimalDigits,
    getShortTitleForAttribute
} from '../../utils/common_utils';
import {
    normalizeAttributeName,
    METADATA_MAP_KEY_SEPARATOR,
    METADATA_MAP_VALUE_SEPARATOR, FQ_TOOLTIP_PLACEHOLDER,
    normalizeChildDatasetName
} from "../../utils/attribute_name_utils";

import { getLastProcessing, getMetaDatas } from "../../utils/dataProfileEventHandling"
import moment from 'moment';
import Select, {
    components,
} from 'react-select';
import Form from 'react-bootstrap/Form';
import { faLayerGroup, faTag, faDatabase } from '@fortawesome/free-solid-svg-icons';

const customSelectDatasource = ({ children, ...props }) => (
    <components.Control {...props} className="has-icon">
        <FontAwesomeIcon
            icon={faDatabase}
            className="form-control__icon"
        />
        {children}
    </components.Control>

);

const customSelectDataset = ({ children, ...props }) => (
    <components.Control {...props} className="has-icon">
        <FontAwesomeIcon
            icon={faLayerGroup}
            className="form-control__icon"
        />
        {children}
    </components.Control>

);


const customSelectAttribute = ({ children, ...props }) => (
    <components.Control {...props} className="has-icon">
        <FontAwesomeIcon
            icon={faTag}
            className="form-control__icon"
        />
        {children}
    </components.Control>

);

const renderTooltipSummary = (props) => (
    <span {...props}>
        {props}
    </span>
);

class Attribute extends React.Component {

    constructor(props) {
        super(props);
        this.getApi = getApi.bind(this);
        this.setApiResponse = this.setApiResponse.bind(this);
        this.changeSource = this.changeSource.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.closeLastProfiledTime = this.closeLastProfiledTime.bind(this);
        this.setFilteredValues = this.setFilteredValues.bind(this);
        this.setChipData = this.setChipData.bind(this);
        this.removeChipData = this.removeChipData.bind(this);
        this.filterInfoData = this.filterInfoData.bind(this);
        this.filterOptions = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.getMetaDatas = getMetaDatas.bind(this);

        this.getLastProcessing = getLastProcessing.bind(this);
        this.handleApplyDatasource = this.handleApplyDatasource.bind(this);
        this.handleApplyDataset = this.handleApplyDataset.bind(this);
        this.handleApplyAttribute = this.handleApplyAttribute.bind(this);
        this.clearAllFilterData = this.clearAllFilterData.bind(this);
        this.attributeProfileFilterSubmit = attributeProfileFilterSubmit.bind(this)
        this.applyTimeFilterToChart = this.applyTimeFilterToChart.bind(this);


        this.state = {
            chipData: [{
                "key": this.props.dataModule.selectedDataSource,
                "value": this.props.dataModule.integrations[this.props.dataModule.selectedDataSource],
                "type": DATA_SOURCE
            }],
            dataSourceId: this.props.dataModule.selectedDataSource,
            isFilterMode: true,
            filteredValues: [],
            cardName: '',
            showModal: false,
            hideLastProfiledTime: false,
            last_processed_time: "",
            dataSetId: this.props.seletedDatasetID,
            attributeName: this.props.seletedAttribute,
            // attributeID: props.dataModule.selectedAttributeID,
            dataSetName: "",
            datasetName: "",

            dataType: props.dataType,
            nativeType: props.nativeType,
            profileData: props.profileData,
            metricsData: props.metricsData,
            loadValue: props.loadValue,
            statisticsData: props.statisticsData,
            metricsOptions: props.metricsOptions,
            attributeProfileOptions: props.attributeProfileOptions,
            driftOnMetricsChart: props.driftOnMetricsChart,
            asterPlotChart: props.asterPlotChart,
            dataDistribution: props.dataDistribution,
            distributionData: props.distributionData,
            outlier: props.outlier,
            size: props.size,
            apiStatusCode: null,
            driftOnMetricsKey: null,
            showAggregateError: false,
            showMetricError: false,
            businessRule: null,
            tableCount: 13,
            expand: false,
            environmentOptions: [],
            dataSourceOptions: [],
            dataSetsOptions: [],
            attributesOptions: [],
            // filterData:[],
            filterData: this.props.filterData,
            showLoader: this.props.showLoader,
            preSelectedEnvironment: [],
            preSelectedDataSource: [],
            preSelectedDatasets: [],
            preSelectedAttributes: [],
            integrationsMap: [],
            processTiming: '',
            seletedDatasourceId: "",
            seletedDatasourceName: "",
            startDate: moment(this.props.dataModule.startDate).format('YYYY-MM-DD 00:00:00'),
            endDate: moment(this.props.dataModule.endDate).format('YYYY-MM-DD 23:59:59'),
            attr_datasource_id: "",
            attr_datasource_value: "",
            attr_dataset_id: "",
            attr_dataset_value: "",
            attr_attribute_id: "",
            attr_attribute_value: "",
            attr_filter_datset_option: [],
            attr_filter_attr_option: [],
            clearAllFilter: false,
            profiletimeresult: [],
            inProgress:this.props.inProgress,
            noDataFound:this.props.noDataFound,
            progressStatus:this.props.progressStatus,
        }
    }

    clearAllFilterData() {
        this.setState({
            clearAllFilter: true,
            attr_datasource_id: "",
            attr_datasource_value: "",
            attr_dataset_id: "",
            attr_dataset_value: "",
            attr_attribute_id: "",
            attr_attribute_value: "",
            attr_filter_datset_option: [],
            attr_filter_attr_option: []
        });
    }


    componentDidMount() {
        if (
            this.state.attributeName !== "" && this.state.attributeName !== undefined &&
            this.state.dataSourceId !== "" && this.state.dataSourceId !== undefined &&
            this.state.dataSetId !== "" && this.state.dataSetId !== undefined
        ) {
            /**While clicking attributes from other tab will get into this if condition*/

            let datasourceOptions = this.props.filterData["datasourceOptions"]
            let attr_datasource_value;
            let attr_datasource_id;
            datasourceOptions.map((data) => {
                if (data.data_set_ids !== undefined && data.data_set_ids.length > 0 && data.data_set_ids.includes(Number(this.state.dataSetId))) {
                    attr_datasource_value = data.label;
                    attr_datasource_id = data.value;
                }
                return null
            })

            let datasetsOptions = this.props.filterData["datasetsOptions"].filter((data) =>
                parseInt(data.value) === parseInt(this.state.dataSetId)
            )

            let attr_dataset_value;
            let attr_dataset_id;
            if (datasetsOptions.length > 0) {
                attr_dataset_value = datasetsOptions[0]["label"];
                attr_dataset_id = datasetsOptions[0]["value"];
            }

            let attributesOptions;
            if (this.props.filterData["attributesOptions"][parseInt(this.state.dataSetId)] !== undefined) {
                attributesOptions = this.props.filterData["attributesOptions"][parseInt(this.state.dataSetId)].filter((data) =>
                    data.label === this.state.attributeName
                )
            }

            let attr_attribute_value;
            let attr_attribute_id;
            if (attributesOptions.length > 0) {
                attr_attribute_value = attributesOptions[0]["label"];
                attr_attribute_id = attributesOptions[0]["value"];
            }


            let dataset_data = this.props.filterData["datasetsOptions"].filter((data) =>
                parseInt(data.integration_id) === parseInt(attr_datasource_id) || data.integration_id === "-1"
            )

            let attribute_data = this.props.filterData["attributesOptions"][parseInt(this.state.dataSetId)].filter((data) =>
                parseInt(data.data_set_id) === parseInt(this.state.dataSetId)
            )

            let sortedDatasetsOptions=[]
            if(Object.keys(dataset_data).length >0){
                sortedDatasetsOptions = sortDict(dataset_data)
            }

            this.setState({
                attr_filter_datset_option: sortedDatasetsOptions,
                attr_filter_attr_option: attribute_data,
                attr_datasource_value: attr_datasource_value,
                attr_datasource_id: attr_datasource_id,
                attr_dataset_value: attr_dataset_value,
                attr_dataset_id: attr_dataset_id,
                attr_attribute_value: attr_attribute_value,
                attr_attribute_id: attr_attribute_id
            });
            this.attributeProfileFilterSubmit(
                this.state.dataSetId,
                attr_attribute_id
            );
            this.getLastProcessing("getonlyprofiletime", attr_datasource_id)
        }
        else {
            /** By default while coming inside the attribute tab will get into this else condition   */
            this.getLastProcessing("");
        }
    }

    handleApplyDatasource(key) {
        let cur_data = this.props.filterData["datasetsOptions"].filter((data) =>
            data.integration_id === key.value || data.integration_id === "-1"
        )
        let sortedDatasetsOptions=[]

        if(cur_data.length === 0){
            this.setState({noDataComponent:true, attr_datasource_value: key.label})
            this.setState({
                attr_filter_datset_option: sortedDatasetsOptions,
                attr_datasource_value: key.label,
                attr_datasource_id: key.value,
                attr_filter_attr_option: [],
                attr_dataset_value: "",
                attr_dataset_id: "",
                attr_attribute_value: "",
                attr_attribute_id: ""
            });
            return
        }


        cur_data = cur_data.map((data) => {
            let splitLable = data.label.split('( ')
            data['label'] = splitLable[0]
            return data
        })

        if(Object.keys(cur_data).length >0){
            sortedDatasetsOptions = sortDict(cur_data)
        }

        let profiletimeresult = this.state.profiletimeresult.filter((data) =>
            data.integration_id === key.value
        )
        if (profiletimeresult.length > 0) {
            this.setState({ processTiming: profiletimeresult[0] })
        }

        this.setState({
            attr_filter_datset_option: sortedDatasetsOptions,
            attr_datasource_value: key.label,
            attr_datasource_id: key.value,
            attr_filter_attr_option: [],
            attr_dataset_value: "",
            attr_dataset_id: "",
            attr_attribute_value: "",
            attr_attribute_id: ""
        });
    }

    handleApplyDataset(key) {

        let attr_filter_attr_option = this.props.filterData["attributesOptions"][key.value].map(attribute => ({
            ...attribute,
            label: normalizeAttributeName(attribute.label)
        }));
        this.setState({
            attr_filter_attr_option: attr_filter_attr_option,
            attr_dataset_value: key.label,
            attr_dataset_id: key.value,
            attr_attribute_value: "",
            attr_attribute_id: ""
        });
    }

    handleApplyAttribute(key) {
        this.setState({
            attr_attribute_value: key.label,
            attr_attribute_id: key.value,
        });
        this.attributeProfileFilterSubmit(this.state.attr_dataset_id, key.value);
    }

    closeLastProfiledTime() {
        this.setState({
            hideLastProfiledTime: true,
        });
    }

    handleLoad(value) {
        this.setState({ loadValue: value });
    }

    setFilteredValues(filteredValues, attributeName, attributeId) {
        let dataSetId = filteredValues[0].split(".")[2];
        this.setState({
            filteredValues: filteredValues,
            attributeName: attributeName,
            attributeID: attributeId,
            dataSetId: dataSetId
        })
        this.attributeProfileFilterSubmit(dataSetId, attributeId);
    }


    setApiResponse(data, apiStatusCode, isFilterApplied) {
        let sourceId = this.state.dataSourceId;
        let sourceName = ""
        let dataSetId = this.state.dataSetId === -1 ? this.props.dataModule.selectedDataSetId : this.state.dataSetId;
        Object.keys(this.props.dataModule.metaData).forEach(key => {
            let reqSplitArr = key.split(METADATA_MAP_KEY_SEPARATOR);
            if (reqSplitArr[2] === dataSetId) {
                sourceId = reqSplitArr[1];
                let reqMappedValues = this.props.dataModule.metaData[key];
                sourceName = reqMappedValues.split(METADATA_MAP_VALUE_SEPARATOR)[1];
                if (isFilterApplied === false) {
                    this.setChipData(-1, { "value": sourceId, "label": sourceName }, -1, -1);
                }
                return sourceId;
            }
        });
        this.setState({ dataSourceId: sourceId });

        let stringDriftOnMetricsFilter = ["empty", "unique", "duplicate"];
        let attributeDataType = data["data_type"];
        let businessRule = data["business_rule"]
        let profileData = (data["data_profile"] !== null && data["data_profile"] !== undefined && data["data_profile"].length > 0) ? data["data_profile"][0] : null;
        let statisticsData = (data["statistics"] !== null && data["statistics"] !== undefined && data["statistics"].length > 0) ? data["statistics"][0] : null;
        let distributionData = (data["data_distribution"] !== null && data["data_distribution"] !== undefined && data["data_distribution"].length > 0) ? data["data_distribution"] : null;

        let metricsOptions = [];
        let attributeProfileOptions = [];
        //let colors = ["#F14357", "#7F3C8D","#11A579","#53A2E3", "#F4AD49", "#6757E2", "#69DE92", "#E7654D", "#F04576", "#52C7EB"];
        let metricsChartData = null;
        let asterPlotChartData = [];
        let metricsData = [];
        let datasetName = data.data_set_name;
        let versionName = data.version_name;

        if (data["metrics"] !== null && data["metrics"] !== undefined) {
            for (let i = 0; i < data["metrics"].length; i++) {
                let obj = data["metrics"][i];
                let metrics = Array.isArray(obj);
                if (metrics) {
                    for (let k = 0; k < obj.length; k++) {
                        let row = obj[k];
                        if (row["name"] === "Data Drift" && NON_NUMERIC_COLUMNS.includes(attributeDataType)) {
                            continue
                        }
                        row.datasetName = datasetName;
                        row.version_name = versionName;
                        metricsData.push(row);
                        let latest_drift;
                        let drift_patterns = row["drift_patterns"];
                        if (drift_patterns === undefined) {
                            drift_patterns = [];
                        }
                        if (BOX_PLOT_METRICS.includes(row["name"])) {
                            let boxPlotData = {}
                            let statistics = {}
                            let quantile = row["boxplot_data"]
                            if (quantile !== undefined && quantile !== null) {
                                let isBoxplot = quantile.filter(x => x !== "NA")
                                if (isBoxplot.length > 0) {
                                    statistics["attribute_name"] = row["attributeName"]
                                    statistics["quantile"] = quantile
                                    statistics["drift_patterns"] = row["drift_patterns"]
                                    statistics["time"] = row["time"]
                                    boxPlotData["chart_level"] = "Attribute"
                                    boxPlotData["statistics"] = [statistics]
                                    row["boxPlotData"] = boxPlotData
                                    row["chartType"] = "boxPlotZoomable"
                                }
                            } else {
                                boxPlotData["statistics"] = [statistics]
                                row["boxPlotData"] = boxPlotData
                            }
                        }
                        if (drift_patterns.length > 0) {
                            latest_drift = row["drift_patterns"][drift_patterns.length - 1]
                        }
                        if (latest_drift !== undefined && latest_drift !== null) {
                            if (latest_drift === "NO_DRIFT" || latest_drift === "not_computed") {
                                metricsOptions.push({
                                    "value": row['name'],
                                    "label": <a className="qd-cw_dp-item" href="/#">{row["name"]}</a>
                                });
                            } else {
                                this.setState({ showMetricError: true });
                                metricsOptions.push({
                                    "value": row['name'],
                                    "label": <a className="qd-cw_dp-item" href="/#">{row["name"]}
                                        <span className="label label-danger">Error</span></a>
                                });
                            }
                        }
                        if (metricsChartData === null) {
                            metricsChartData = row;
                        }
                        //let colorIndex = (asterPlotChartData.length % 10)+1;
                        //if (row["latest_value"] > 0 ) {
                        //    asterPlotChartData.push({"id": row["name"], "order": i, "color": colors[colorIndex],
                        //        "weight": 1, "score": row["latest_value"], "width": 1, "label": row["name"]});
                        //}
                        asterPlotChartData.push([row["name"], convertDecimalDigits(row["latest_value"])]);
                    }
                } else {
                    // eslint-disable-next-line
                    if (obj !== null && obj !== undefined) {
                        // eslint-disable-next-line
                        Object.keys(obj).forEach(function (key) {
                            let row = obj[key];
                            metricsData.push(row);
                            let latest_drift;
                            let drift_patterns = row["drift_patterns"]
                            if (drift_patterns !== undefined) {
                                if (drift_patterns.length > 0) {
                                    latest_drift = row["drift_patterns"][drift_patterns.length - 1]
                                }
                            }
                            if (attributeDataType === "string") {
                                if (stringDriftOnMetricsFilter.includes(row['name'])) {
                                    if (latest_drift !== undefined && latest_drift !== null) {
                                        if (latest_drift === "NO_DRIFT" || latest_drift === "not_computed") {
                                            attributeProfileOptions.push({ "value": row['name'], "label": <a className="qd-cw_dp-item" href="/#">{row["name"]}</a> });
                                        } else {
                                            this.setState({ showAggregateError: true });
                                            attributeProfileOptions.push({ "value": row['name'], "label": <a className="qd-cw_dp-item" href="/#">{row["name"]} <span class="label label-danger">Error</span></a> });
                                        }
                                    } else {
                                        attributeProfileOptions.push({ "value": row['name'], "label": <a className="qd-cw_dp-item" href="/#">{row["name"]}</a> });
                                    }
                                }
                            } else {
                                if (latest_drift !== undefined && latest_drift !== null) {
                                    if (latest_drift === "NO_DRIFT" || latest_drift === "not_computed") {
                                        attributeProfileOptions.push({ "value": row['name'], "label": <a className="qd-cw_dp-item" href="/#">{row["name"]}</a> });
                                    } else {
                                        this.setState({ showAggregateError: true });
                                        attributeProfileOptions.push({ "value": row['name'], "label": <a className="qd-cw_dp-item" href="/#">{row["name"]} <span class="label label-danger">Error</span></a> });
                                    }
                                } else {
                                    attributeProfileOptions.push({ "value": row['name'], "label": <a className="qd-cw_dp-item" href="/#">{row["name"]}</a> });
                                }
                            }
                            if (metricsChartData === null) {
                                metricsChartData = row;
                            }
                        });
                    }

                }
            }
        }

        if (metricsChartData !== null) {
            metricsChartData['datasetName'] = datasetName;
            metricsChartData['version_name'] = versionName;
            metricsChartData['data_source_name'] = sourceName;
            metricsChartData['attribute_name'] = data["attribute_name"];
            // TODO assiging avg drift value as error percentage need to confirm
            metricsChartData['error_percentage'] = this.findAverage(metricsChartData["drift"]).toFixed(2);
        }

        if (distributionData !== null) {
            distributionData['data_source_name'] = sourceName;
            distributionData['attribute_name'] = data["attribute_name"];
            distributionData['datasetName'] = data["data_set_name"];
        }

        if (asterPlotChartData !== null) {
            asterPlotChartData['data_source_name'] = sourceName;
            asterPlotChartData['attribute_name'] = data["attribute_name"];
            asterPlotChartData['datasetName'] = data["data_set_name"];
        }

        if (metricsChartData === null) {
            metricsChartData = undefined;
        }

        let _chartName = '';
        if (metricsChartData !== undefined) {
            _chartName = metricsChartData.name;
        }

        let size = data["size"]
        if (size === undefined) {
            size = 0;
        }

        let digits_size = size.toString().length;
        if (digits_size <= 5) {
            size = (size / 1000).toFixed(2) + " KB"
        } else {
            size = (size / 1000000).toFixed(2) + " MB"
        }

        let environment = -1;
        let dataSource = -1;
        if (this.props.dataModule !== undefined && this.props.dataModule.integrations !== undefined) {
            for (const [key, value] of Object.entries(this.props.dataModule.integrations)) {
                if (key === this.state.dataSourceId.toString()) {
                    dataSource = { "label": value, "value": key };
                }
            }
            for (const [key, value] of Object.entries(this.props.dataModule.environmentDataSourceMapping)) {
                if (value.includes(this.state.dataSourceId.toString())) {
                    environment = { "label": this.props.dataModule.environments[key], "value": key };
                }
            }
        }
        if (environment !== -1) {
            this.setState({ prefillEnvironment: environment });
        }
        if (dataSource !== -1) {
            this.setState({ prefillDataSource: dataSource });
        }

        this.setState({
            dataSetName: data["data_set_name"],
            datasetName: data["data_set_name"],
            attributeName: data["attribute_name"],
            dataType: data["data_type"],
            nativeType: data["native_type"],
            count: data["count"],
            outlier: data["outlier"],
            businessRule: businessRule,
            size: size,
            profileData: profileData,
            metricsData: metricsData,
            distributionData: distributionData,
            statisticsData: { "attribute_name": data["attribute_name"], statistics: statisticsData },
            metricsOptions: metricsOptions,
            attributeProfileOptions: attributeProfileOptions,
            driftOnMetricsChart: metricsChartData,
            asterPlotChart: asterPlotChartData,
            apiStatusCode: apiStatusCode,
            driftOnMetricsKey: "attribute_" + data["attribute_name"] + _chartName,
            showLoader: false,
            noDataComponent:false
        });
    }

    getData() {
        // this.getApi("attributePage");
    }

    changeSource(metricName, stateKey, title, expand, startDate = null, endDate = null) {
        let finalData = null
        let filteredData = null
        let isTimeFilter = false

        if (startDate === null) {
            startDate = this.state.startDate;
        }
        else {
            isTimeFilter = true
            this.setState({ startDate: startDate })
        }

        if (endDate === null) {
            endDate = this.state.endDate;
        }
        else {
            this.setState({ endDate: endDate })
        }
        if (this.state.metricsData !== null) {
            for (let row of this.state.metricsData) {
                if (row["name"] === metricName) {
                    finalData = row;
                    finalData['data_source_name'] = this.state.datasourceName;
                    finalData["datasetName"] = this.state.datasetName;
                    finalData['attribute_name'] = this.state.attributeName;
                    finalData['error_percentage'] = this.findAverage().toFixed(2);
                    if (finalData["name"] === "Data Drift") {
                        finalData["boxPlotData"] = filterBoxPlotData(finalData["boxPlotData"])
                    }

                    filteredData = getChartFilterData(finalData, startDate, endDate)
                    if (filteredData !== undefined && filteredData !== null && filteredData.time.length !== undefined) {
                        finalData = filteredData;
                    }

                    if (isTimeFilter === true) {
                        finalData = filteredData
                    }

                    break;
                }
            }
        }

        let isChartChanged = !this.state.isChartChanged
        const metricKey = "attribute_" + metricName + String(isChartChanged);

        this.setState({
            driftOnMetricsChart: finalData, filteredData: filteredData,
            loadValue: false, driftOnMetricsKey: metricKey, expand: expand
        });

        this.setState({ isChartChanged: !this.state.isChartChanged });
    }




    findAverage(inputArr) {
        if (inputArr !== null && inputArr !== undefined) {
            return inputArr.reduce((a, b) => a + b) / inputArr.length;
        } else {
            return 0;
        }
    }


    filterInfoData(filteredValues, infoData) {
        let updatedInfoData = {}
        filteredValues.forEach((filteredValue) => {
            const filtered = Object.keys(infoData)
                .filter(key => key.startsWith(filteredValue))
                .reduce((obj, key) => {
                    obj[key] = infoData[key];
                    return obj;
                }, {});
            updatedInfoData = { ...updatedInfoData, ...filtered }
        });
        return updatedInfoData;
    }

    applyTimeFilterToChart(currentData, startDate, endDate, chartType, isPatternNotNeeded) {
        let metricName;
        if (currentData !== null && currentData !== undefined) {
            metricName = currentData["name"]
            this.setState({ currentMetricOnChart: currentData["name"] })
        }
        else {
            metricName = this.state.currentMetricOnChart
        }
        let expand = this.state.expand
        this.changeSource(metricName, "", "", expand, startDate, endDate)
    }

    setChipData(environment, dataSource, dataSet, attribute) {
        let chipData = [];
        if (environment !== -1) {
            chipData.push({ "key": environment.value, "value": environment.label, "type": ENVIRONMENT });
            this.setState({ prefillEnvironment: environment })
        }
        let dataSourceId = this.props.dataModule.selectedDataSource;
        if (dataSource !== -1) {
            dataSourceId = dataSource.value;
            chipData.push({ "key": dataSource.value, "value": dataSource.label, "type": DATA_SOURCE });
        }
        if (dataSet !== -1) {
            chipData.push({ "key": dataSet.value, "value": dataSet.label, "type": DATA_SET });
            this.props.setDataSet(dataSet.value);
        }
        if (attribute !== -1) {
            chipData.push({ "key": attribute.value, "value": attribute.label, "type": ATTRIBUTE });
            this.props.setAttribute(attribute.value, attribute.label);
        }

        this.setState({
            chipData: chipData,
            dataSourceId: dataSourceId,
            prefillDataSource: dataSource
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dataModule.selectedAttributeID === "" && this.props.dataModule.selectedAttributeID !== "") {
            this.getApi("attributePage");
        }

    }

    removeChipData(key) {
        let chipData = this.state.chipData;
        chipData = chipData.filter((item) => item.key !== key);
        let filteredValues = [];
        let environment = chipData.filter((item) => item.type === ENVIRONMENT);
        let dataSource = chipData.filter((item) => item.type === DATA_SOURCE);
        if (environment.length === 0 || dataSource.length === 0) {
            chipData = [{
                "key": this.state.dataSourceId,
                "value": this.props.dataModule.integrations[this.state.dataSourceId],
                "type": DATA_SOURCE
            }];
            this.filterOptions.current.setDefaultValuesFromParent(-1, -1, -1, -1);
        } else {
            let prefix = `${environment[0].key}.${dataSource[0].key}`;
            let dataSets = chipData.filter((item) => item.type === DATA_SET);
            let attributes = chipData.filter((item) => item.type === ATTRIBUTE);
            if (dataSets.length !== 0) {
                dataSets.forEach((dataSet) => {
                    if (attributes.length !== 0) {
                        attributes.forEach((attribute) => {
                            filteredValues.push(`${prefix}.${dataSet.key}.${attribute.key}`);
                        });
                    } else {
                        filteredValues.push(`${prefix}.${dataSet.key}`);
                        this.filterOptions.current.setDefaultValuesFromParent(null, null, null, -1);
                    }
                });
            } else {
                filteredValues.push(prefix);
                this.filterOptions.current.setDefaultValuesFromParent(null, null, -1, null);
            }
        }
        this.setState({
            chipData: chipData,
            filteredValues: filteredValues
        });
    }

    chooseLoadingComponent(emptyContentMessage) {
        if (this.state.profileData === null || this.state.profileData === undefined) {
            let returnClass;
            let apiStatus = this.state.apiStatusCode;
            if (apiStatus === 200) {
                returnClass = <EmptyAPIResponse
                    page={"attribute"}
                    content={emptyContentMessage}
                />;
            } else {
                returnClass = <NoDataComponent
                    noContentSubtext={"Please Configure datasource to view Attributes."}
                    customButtonName={"Configure Datasource"}
                    navigatePage={"/configure"}
                    noIntegration={this.props.noDataFound}
                />;
            }
            return returnClass;
        }

    }

    getDefaultSelectedMetrics() {
        let selectedMetricOptions = [];
        if (this.state.metricsOptions !== null) {
            selectedMetricOptions = this.state.metricsOptions[0];
            return selectedMetricOptions;
        } else {
            return selectedMetricOptions;
        }
    }

    toggleFilter() {
        let filterMode = this.state.isFilterMode ? false : true;
        this.setState({
            isFilterMode: filterMode
        })
    }

    resetFilter() {
        this.setState({ formResetFlag: 1 });
    }


    /*renderFilter(environmentOption) {
        // let maxLastProfilingTime = results.reduce((a, b) => Number(getDateObject(results[a])) > Number(getDateObject(results[b])) ? a : b);

        // let dataSourceOption = [];
        // let dataSetOptions = [];
        let preSelectedEnvironment = this.state.prefillEnvironment;
        let preSelectedDataSource = this.state.prefillDataSource;
        let preSelectedDatasets = this.state.preSelectedDatasets;
        let preSelectedAttributes = this.state.preSelectedAttributes;
        // let attributeOptions = [];
        // let datasetIds = [];

        // if (this.props.dataModule !== undefined && preSelectedDataSource !== undefined && preSelectedEnvironment !== undefined &&
        //         preSelectedEnvironment !== -1 && this.props.dataModule.dataSourceDataSetMapping !== undefined) {
        //         let dataSources = this.props.dataModule.environmentDataSourceMapping[preSelectedEnvironment.value];
        //         dataSources.forEach((dataSourceId) => {
        //             if(this.props.dataModule["info"][dataSourceId] === undefined && this.props.dataModule.integrations[dataSourceId] !== undefined) {
        //                 dataSourceOption.push({"value": dataSourceId, "label":<div>{this.props.dataModule.integrations[dataSourceId]}<Image width="100" height="25" src="loader"/></div>, "isDisabled": true});
        //             }else if(this.props.dataModule.integrations[dataSourceId] !== undefined) {
        //                 dataSourceOption.push({"value": dataSourceId, "label":this.props.dataModule.integrations[dataSourceId]});
        //             }
        //         });
        //         let dataSets = this.props.dataModule.dataSourceDataSetMapping[preSelectedDataSource.value];
        //         if (dataSets !== undefined) {
        //             dataSets.forEach((dataSetId) => {
        //                 if (!datasetIds.includes(dataSetId)) {
        //                     datasetIds.push(dataSetId);
        //                     dataSetOptions.push({
        //                         "value": dataSetId, "label": parseMetaMapping(this.props, DATA_SET,
        //                             preSelectedDataSource.value, dataSetId, this.state.prefillEnvironment, preSelectedDataSource)
        //                     });
        //                 }
        //                 if(dataSetId === this.props.dataModule.selectedDataSetId) {
        //                     let currDatasetName = parseMetaMapping(this.props, DATA_SET,
        //                         preSelectedDataSource.value, dataSetId, this.state.prefillEnvironment, preSelectedDataSource);
        //                    currDatasetName = normalizeChildDatasetName(currDatasetName);
        //                    preSelectedDatasets = {"label": currDatasetName, "value": dataSetId }
        //                 }
        //             })

        //         }
        //         if(preSelectedDatasets !== -1) {
        //             let attributes = this.props.dataModule.dataSetAttributeMapping[preSelectedDatasets.value];
        //                    attributes.forEach((attributeId) => {
        //                         let attributeNameValue = parseMetaMapping(this.props, ATTRIBUTE, preSelectedDatasets.value,
        //                          attributeId, preSelectedEnvironment, preSelectedDataSource)
        //                         attributeNameValue = normalizeAttributeName(attributeNameValue);
        //                         attributeOptions.push({"value": attributeId, "label": attributeNameValue});
        //                         if(attributeNameValue === normalizeAttributeName(this.props.dataModule.selectedAttributeName)) {
        //                             preSelectedAttributes = {"label": attributeNameValue, "value": attributeId}
        //                         }
        //                     });

        //         }
        // }

        // dataSourceOption = this.state.dataSourceOptions[this.state.prefillEnvironment.value]

        return (
            <FilterOptions
                ref={this.filterOptions}
                id="attributeFilter"
                show={this.state.isFilterMode}
                resetFilter={1}
                closeFilterModal={this.toggleFilter}
                dataModule={this.props.dataModule}
                setFilteredValues={this.setFilteredValues}
                environments={this.state.environmentOptions}
                dataSourceOption={this.state.dataSourceOptions}
                dataSetOptions={this.state.dataSetsOptions}
                attributeOptions ={this.state.attributesOptions}

                preSelectedEnvironment={preSelectedEnvironment}
                preSelectedDataSource={preSelectedDataSource}
                preSelectedDatasets={preSelectedDatasets}
                preSelectedAttributes={preSelectedAttributes}
                formResetFlag ={1}
                filterTitle="Attribute Filter"
                setChipData={this.setChipData}
            />
        );
    }*/


    selectedDataSource() {
        let integrationId = this.state.dataSourceId;
        let integrationName = this.props.dataModule.integrations[integrationId];
        return integrationName
    }


    convertDecimal(drifts) {
        for (let i = 0; i < drifts.length && drifts[i] !== null && drifts[i] !== "Null"; i++) {
            drifts[i] = parseFloat((drifts[i]).toFixed(4))
        }
        return drifts
    }

    handleClick(name) {
        this.setState({ showModal: true, cardName: name });
    }

    hideModal = () => {
        this.setState({ showModal: false });
    }

    removeStringValuesFromChart(driftOnMetricsChart) {
        let indexes = driftOnMetricsChart["drift"].length;
        for (let i = 0; i < indexes; i++) {
            if ((typeof (driftOnMetricsChart["drift"][i]) === 'string' && driftOnMetricsChart["drift"][i] !== "Null") ||
                ((typeof (driftOnMetricsChart["value"][i]) === 'string') && driftOnMetricsChart["value"][i] !== "Null")) {
                // remove values
                driftOnMetricsChart["drift"].splice(i, 1);
                driftOnMetricsChart["value"].splice(i, 1);
                driftOnMetricsChart["drift_pattern"].splice(i, 1);
                driftOnMetricsChart["time"].splice(i, 1);
                i-- // decrement index;
            }
        }
        return driftOnMetricsChart;
    }

    renderFilter() {
        let attributeOptions = this.state.attr_filter_attr_option.map(option => ({
            ...option,
            label: normalizeAttributeName(option.label) 
        }));
    
        return <div className="qd-filter-section">
            <div className="qd-filter__content">
                <div className="qd-filter__header">
                    <h5 className="qd-filter__title">Data Relationship Filter</h5>
                    <button type='button' className="close"><span className="sr-only">Close</span></button>
                </div>
                <div className="qd-filter__body">
                    <div className="qd-filter__options">
                        <div className={this.state.clearAllFilter === true && this.state.attr_datasource_id === "" ? "has-error textfield-outlined" : "textfield-outlined"}>
                            <Form.Label>Datasource</Form.Label>
                            <Select
                                onFocus={this.handleFocus}
                                value={this.state.clearAllFilter === true && this.state.attr_datasource_id === "" ? { value: "-1", label: "" } : { value: this.state.attr_datasource_id, label: this.state.attr_datasource_value }}
                                id="chooseDatasource"
                                name="data_sources"
                                options={this.state.filterData['datasourceOptions']}
                                onChange={this.handleApplyDatasource}
                                classNamePrefix='form-control'
                                placeholder="Choose Datasource"
                                components={{ Control: customSelectDatasource }}
                            />
                        </div>
                        <div className={this.state.clearAllFilter === true && this.state.attr_dataset_id === "" ? "has-error textfield-outlined" : "textfield-outlined"}>
                            <Form.Label>Datasets</Form.Label>
                            <Select
                                // attr_filter_datset_option: cur_data,
                                // attr_datasource_value:key.value,
                                // attr_datasource_id:key.value,
                                onFocus={this.handleFocus}
                                value={this.props.clearAllFilter === true && this.state.attr_dataset_id === "" ? { value: "-1", label: "" } : { value: this.state.attr_dataset_id, label: this.state.attr_dataset_value }}
                                id="chooseDataset"
                                name="dataset"
                                options={this.state.attr_filter_datset_option}
                                onChange={this.handleApplyDataset}
                                classNamePrefix='form-control'
                                placeholder="Choose Dataset"
                                components={{ Control: customSelectDataset }}
                            />
                        </div>
                        <div className={this.state.clearAllFilter === true && this.state.attr_attribute_id === "" ? "has-error textfield-outlined" : "textfield-outlined"}>
                            <Form.Label>Attributes</Form.Label>
                            <Select
                                onFocus={this.handleFocus}
                                value={this.props.clearAllFilter === true && this.state.attr_attribute_id === "" ? { value: "-1", label: "" } : { value: this.state.attr_attribute_id, label: this.state.attr_attribute_value }}
                                id="chooseAttribute"
                                name="attribute"
                                options={attributeOptions}
                                onChange={this.handleApplyAttribute}
                                classNamePrefix='form-control'
                                placeholder="Choose Attribute"
                                components={{ Control: customSelectAttribute }}
                            />
                        </div>
                        {/* <span className="dot-separator"></span> */}
                        <span className="line-separator"></span>
                        <button className="btn btn-filter-reset shadow-none px-2" onClick={this.clearAllFilterData}>Clear All</button>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        let driftOnMetricsChart = this.state.driftOnMetricsChart
        if (driftOnMetricsChart !== undefined && driftOnMetricsChart !== null) {
            const has_string = (element) => typeof (element) === 'string' && element !== "Null";
            if (driftOnMetricsChart["drift"].some(has_string) || driftOnMetricsChart["value"].some(has_string)) {
                driftOnMetricsChart = this.removeStringValuesFromChart(driftOnMetricsChart)
            }
            driftOnMetricsChart["drift"] = this.convertDecimal(driftOnMetricsChart["drift"])
            driftOnMetricsChart["value"] = this.convertDecimal(driftOnMetricsChart["value"])
            driftOnMetricsChart["drift"] = driftOnMetricsChart["value"]
            driftOnMetricsChart["errorChart"] = true;
        }

        let profileData = this.state.profileData;
        let keys = ["avg", "min", "max", "std"]
        if (profileData !== undefined && profileData !== null) {
            for (let i = 0; i < keys.length; i++) {
                if (typeof (profileData[keys[i]]["drift"]) === "number") {
                    profileData[keys[i]]["drift"] = parseFloat(profileData[keys[i]]["drift"].toFixed(4))
                }
                if (typeof (profileData[keys[i]]["value"]) === "number") {
                    profileData[keys[i]]["value"] = parseFloat(profileData[keys[i]]["value"].toFixed(4))
                }
            }
        }

        let emptyContentMessage = "Profiling overview is not yet available for the selected attribute. Kindly check after some time.";

        let attributeDataType = this.state.dataType !== null ? this.state.dataType : "NA";
        if (this.state.nativeType !== undefined && this.state.nativeType != null) {
            attributeDataType = this.state.nativeType;
        }

        let currAttributeName = this.state.attributeName !== null ? this.state.attributeName : "NA";
        currAttributeName = normalizeAttributeName(currAttributeName);
        let currDatasetName = this.state.dataSetName !== null ? this.state.dataSetName : "NA";
        currDatasetName = normalizeChildDatasetName(currDatasetName);
        let lastProfilingTime
        if(this.state.processTiming !== undefined){
            lastProfilingTime = this.state.processTiming['last_processed_time']
        }
        lastProfilingTime =  lastProfilingTime === undefined || lastProfilingTime === "" ? "--" : "Your data last profiled " + lastProfilingTime
        if(this.state.noDataComponent === true){
            lastProfilingTime = NO_DATASET_NO_ERROR
        }
        return (

            this.props.noDataFound === true ?
                <><NoDataComponent
                    noContentSubtext={"Please Configure datasource to view Attribute."}
                    customButtonName={"Configure Datasource"}
                    navigatePage={"/configure"}
                    noIntegration={this.props.noDataFound} />
                </>
                :
                this.props.inProgress === true && this.state.showLoader === false ?
                    <>
                        {/* {this.state.isFilterMode ? this.renderFilter(environmentOption) : ""}
                        {
                            this.state.showModal ?
                                <CardHelpContent
                                    show={this.state.showModal}
                                    onHide={this.hideModal}
                                    name={this.state.cardName}
                                    page={"attribute"}
                                /> : ""
                        }
                        {
                            this.state.hideLastProfiledTime === false ?
                                <div class="caption"><div class="alert moniker-alert" role="alert"><p class="mb-0"><strong>&nbsp;</strong></p></div></div>
                                : ""
                        } */}
                        <BasicPortlet
                            className="pb-0"
                            video_url="profile_data"
                            title="Observation"
                            bodyClassName="pb-0"
                            id="Observation"
                            content={
                                <>
                                    <ProgressStatus progressStatus={this.state.progressStatus} />
                                </>
                            }
                        />
                    </>
                    :
                    <>
                        {
                            this.state.showModal ?
                                <CardHelpContent
                                    show={this.state.showModal}
                                    onHide={this.hideModal}
                                    name={this.state.cardName}
                                    page={"attribute"}
                                /> : ""
                        }
                        <div className="qd-tab__content-action">
                            {
                                this.state.hideLastProfiledTime === false ?
                                    <div className="caption">
                                        <div className="alert moniker-alert" role="alert">
                                            <p className="mb-0">
                                                <strong>
                                                    {lastProfilingTime}
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="actions flex-shrink-0">
                                <button onClick={this.toggleFilter} className="btn btn-light">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#484848" height="24" className="mr-1"
                                        viewBox="0 0 24 24" width="24">
                                        <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                        <path d="M0 0h24v24H0z" fill="none" />
                                    </svg>
                                    <span className="dot-symbol"></span>
                                    <span>Filters</span>
                                </button>
                            </div>
                        </div>
                        {
                            this.state.profileData !== undefined && this.state.showLoader === false && this.state.noDataComponent !== true ?
                                <>
                                    {this.state.isFilterMode === true ?
                                        this.renderFilter()
                                        : ""}
                                    <Row bsPrefix="form-row">
                                        <Col lg={12}>
                                            <Row bsPrefix="form-row">
                                                <Col lg={4} md={6} xs={12}>
                                                    <Card className="card-attribute">
                                                        <Card.Body>
                                                            <div className="card-content">
                                                                <Card.Title>Errors Found</Card.Title>
                                                                <h1 className="card-text">{this.state.outlier ? this.state.outlier : "0"}</h1>
                                                            </div>
                                                            <div className="card-image">
                                                                <Image
                                                                    className="card_line-graph"
                                                                    src="linegraph"
                                                                />
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={4} md={6} xs={12}>
                                                    <Card className="card-attribute">
                                                        <Card.Body>
                                                            <div className="card-content">
                                                                <Card.Title>Total Space Occupied</Card.Title>
                                                                <h1 className="card-text">{this.state.size ? this.state.size : "0 KB"}</h1>
                                                            </div>
                                                            <div className="card-image">
                                                                <i><FontAwesomeIcon icon={faCloud} /></i>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={4} md={6} xs={12}>
                                                    <Card className="card-attribute">
                                                        <Card.Body>
                                                            <div className="card-content">
                                                                <Card.Title>Number of Records</Card.Title>
                                                                <h1 className="card-text">{this.state.count ? this.state.count : "0"}</h1>
                                                            </div>
                                                            <div className="card-image">
                                                                <i><FontAwesomeIcon icon={faFolderOpen} /></i>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row bsPrefix="form-row">
                                        <Col lg={12}>
                                            <BasicPortlet
                                                video_url="profile_data"
                                                id="Observation"
                                                className="mb-3"
                                                bodyClassName="pb-0"
                                                title="Observation"
                                                srcOption={[]}
                                                content={
                                                    <Row bsPrefix="form-row">
                                                        <Col lg={3} md={6} xs={12}>
                                                            <div className="dashboard-stat dashboard-stat-info">
                                                                <div className="details">
                                                                    <div className="desc-header">
                                                                        <div className="title text-info">Empty values</div>
                                                                    </div>
                                                                    <div className="desc">
                                                                        <span className="desc-value">{this.state.profileData.empty.value !== null ? this.state.profileData.empty.value : "NA"}</span>
                                                                    </div>
                                                                    <Image className="desc-img" src="icon_emptyData" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={6} xs={12}>
                                                            <div className="dashboard-stat dashboard-stat-success">
                                                                <div className="details">
                                                                    <div className="desc-header">
                                                                        <div className="title text-success">Unique values</div>
                                                                    </div>
                                                                    <div className="desc">
                                                                        <span className="desc-value">{this.state.profileData.unique.value !== null ? this.state.profileData.unique.value : "NA"}</span>
                                                                    </div>
                                                                    <Image className="desc-img" src="icon_uniqueData" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={6} xs={12}>
                                                            <div className="dashboard-stat dashboard-stat-warning">
                                                                <div className="details">
                                                                    <div className="desc-header">
                                                                        <div className="title text-warning">Duplicate values</div>
                                                                    </div>
                                                                    <div className="desc">
                                                                        <span className="desc-value">{this.state.profileData.duplicate.value !== null ? this.state.profileData.duplicate.value : "NA"}</span>
                                                                    </div>
                                                                    <Image className="desc-img" src="icon_duplicateData" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={6} xs={12}>
                                                            <div className="dashboard-stat dashboard-stat-danger">
                                                                <div className="details">
                                                                    <div className="desc-header">
                                                                        <div className="title text-danger">Error values</div>
                                                                    </div>
                                                                    <div className="desc">
                                                                        <span className="desc-value">{this.state.outlier !== null ? this.state.outlier : 0}</span>
                                                                    </div>
                                                                    <Image className="desc-img" src="icon_errorData" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row bsPrefix="form-row">
                                        <Col md={4}>
                                            <BasicPortlet
                                                video_url="profile_data"
                                                id="attributeSummary"
                                                className="mb-3"
                                                bodyClassName="p-0"
                                                title="Attribute Summary"
                                                srcOption={[]}
                                                content={
                                                    <div className="portlet-table clearfix">
                                                        <div className="form-group">
                                                            <label>Dataset Name :</label>
                                                            {this.state.dataSetName !== null && this.state.dataSetName.toString().length >= 15 ? <RcTooltip placement="top" overlay={renderTooltipSummary(currDatasetName)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                                <span className="portlet-table-value">{this.state.dataSetName !== null ? currDatasetName : "NA"}</span>
                                                            </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{this.state.dataSetName !== null ? currDatasetName : "NA"}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Attribute Name :</label>
                                                            {currAttributeName.toString().length >= 15 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(currAttributeName)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{currAttributeName}</span>
                                                                </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{currAttributeName}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Type :</label>
                                                            {attributeDataType.toString().length >= 3 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(attributeDataType)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{attributeDataType}</span>
                                                                </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{attributeDataType}</span>
                                                            }


                                                        </div>
                                                        <div className="form-group">
                                                            <label>Empty :</label>
                                                            {profileData.empty.value !== null && profileData.empty.value.toString().length >= 15 ? <RcTooltip placement="top" overlay={renderTooltipSummary(profileData.empty.value)} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                                                <span className="portlet-table-value">{profileData.empty.value !== null ? profileData.empty.value : "NA"}</span>
                                                            </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{profileData.empty.value !== null ? profileData.empty.value : "NA"}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Max :</label>
                                                            {profileData.max.value !== null && profileData.max.value.toString().length >= 15 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(profileData.max.value)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{profileData.max.value !== null ? profileData.max.value : "NA"}</span>
                                                                </RcTooltip>
                                                                : <span className="portlet-table-value">{profileData.max.value !== null ? profileData.max.value : "NA"}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Min :</label>
                                                            {profileData.min.value !== null && profileData.min.value.toString().length >= 15 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(profileData.min.value)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{profileData.min.value !== null ? profileData.min.value : "NA"}</span>
                                                                </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{profileData.min.value !== null ? profileData.min.value : "NA"}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Mean :</label>
                                                            {profileData.avg.value !== null && profileData.avg.value.toString().length >= 15 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(profileData.avg.value)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{profileData.avg.value !== null ? profileData.avg.value : "NA"}</span>
                                                                </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{profileData.avg.value !== null ? profileData.avg.value : "NA"}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Mode :</label>
                                                            {profileData.mode.value !== null && profileData.mode.value.toString().length >= 15 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(profileData.mode.value)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{profileData.mode.value !== null ? profileData.mode.value : "NA"}</span>
                                                                </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{profileData.mode.value !== null ? profileData.mode.value : "NA"}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Average :</label>
                                                            {profileData.avg.value !== null && profileData.avg.value.toString().length >= 15 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(profileData.avg.value)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{profileData.avg.value !== null ? profileData.avg.value : "NA"}</span>
                                                                </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{profileData.avg.value !== null ? profileData.avg.value : "NA"}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>STD :</label>
                                                            {profileData.std.value !== null && profileData.std.value.toString().length >= 15 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(profileData.std.value)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{profileData.std.value !== null ? profileData.std.value : "NA"}</span>
                                                                </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{profileData.std.value !== null ? profileData.std.value : "NA"}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Unique :</label>
                                                            {profileData.unique.value !== null && profileData.unique.value.toString().length >= 15 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(profileData.unique.value)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{profileData.unique.value !== null ? profileData.unique.value : "NA"}</span>
                                                                </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{profileData.unique.value !== null ? profileData.unique.value : "NA"}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Duplicate :</label>
                                                            {profileData.duplicate.value !== null && profileData.duplicate.value.toString().length >= 15 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(profileData.duplicate.value)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{profileData.duplicate.value !== null ? profileData.duplicate.value : "NA"}</span>
                                                                </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{profileData.duplicate.value !== null ? profileData.duplicate.value : "NA"}</span>
                                                            }
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Business Rule :</label>
                                                            {!IS_NONE_CHECK.includes(this.state.businessRule) && this.state.businessRule.toString().length >= 15 ?
                                                                <RcTooltip
                                                                    placement="top"
                                                                    overlay={renderTooltipSummary(this.state.businessRule)}
                                                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                >
                                                                    <span className="portlet-table-value">{this.state.businessRule !== null ? this.state.businessRule : "NA"}</span>
                                                                </RcTooltip>
                                                                :
                                                                <span className="portlet-table-value">{this.state.businessRule !== null ? this.state.businessRule : "NA"}</span>
                                                            }
                                                        </div>
                                                        {/* This empty form-group is add to resolve issue QPS-2189,
                                                    Add +1 whenever the new table value is added
                                                    into Attribute summary
                                                */}
                                                        {this.state.tableCount % 2 === 0
                                                            ?
                                                            ''
                                                            :
                                                            <div className="form-group p-0">
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            />
                                        </Col>
                                        <Col md={8}>
                                            <Row bsPrefix="form-row">
                                                <Col lg={12}>
                                                    <BasicPortlet
                                                        video_url="profile_data"
                                                        id="driftOnMetrics"
                                                        className="mb-3"
                                                        bodyClassName="p-0"
                                                        title="Attribute Drifts"
                                                        srcOption={[]}
                                                        content={
                                                            (this.state.driftOnMetricsChart !== undefined) ?
                                                                <LazyLoadComponent placeholder={<Load isBootStrapColumn={true} />}>
                                                                    <ChartFilterHeader
                                                                        data={this.state.driftOnMetricsChart}
                                                                        key={this.state.driftOnMetricsKey}
                                                                        dataSetId={this.state.dataSetId}
                                                                        selectedIntegration={this.props.selectedIntegration}
                                                                        metricsOptions={this.state.metricsOptions}
                                                                        attributeProfileOptions={this.state.attributeProfileOptions}
                                                                        title="Attribute Drifts"
                                                                        id="driftOnMetrics"
                                                                        chartType="areaChart"
                                                                        page="attribute"
                                                                        variant={this.state.variant}
                                                                        changeSource={this.changeSource}
                                                                        metricsData={this.state.metricsData}
                                                                        handleLoad={this.handleLoad}
                                                                        selectedMetrics={this.getDefaultSelectedMetrics()}
                                                                        customTimeFilterFunction={this.applyTimeFilterToChart}
                                                                        chartTimeFilter={this.chartTimeFilter}
                                                                        dataSetName={currDatasetName}
                                                                        isAttribute={true}
                                                                        showHeatMap={true}
                                                                        showSettingsMenu={false}
                                                                        yValue="Value"
                                                                        xValue="Time"
                                                                        isError={false}
                                                                        scale={MIN_MAX_SCALE}
                                                                        hideDistanceFilter={true}
                                                                        showAggregateError={this.state.showAggregateError}
                                                                        showMetricError={this.state.showMetricError}
                                                                        expand={this.state.expand}

                                                                    />
                                                                </LazyLoadComponent>
                                                                : <NoErrorComponent message={"Profiling overview is not yet available for the selected attribute. Kindly check after some time."} />
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row bsPrefix="form-row">
                                        <Col lg={6}>
                                            <Row bsPrefix="form-row">
                                                <Col lg={12}>
                                                    {this.state.distributionData !== null ?
                                                        <BasicPortlet id="dataDistribution"
                                                            video_url="profile_data"
                                                            className="mb-3"
                                                            title={`Top 20 Frequency of ${currAttributeName}`}
                                                            shortTitle={`${getShortTitleForAttribute(currAttributeName)["requiredName"]}`}
                                                            titlePrefix={`Top 20 Frequency of `}
                                                            toolTipHandle={FQ_TOOLTIP_PLACEHOLDER}
                                                            titleTooltip={getShortTitleForAttribute(currAttributeName)["fqTooltipContent"]}
                                                            srcOption={[]}
                                                            content={<ChartBody
                                                                id="test5"
                                                                page="attribute"
                                                                chartType="barChart"
                                                                style={{ height: "350px" }}
                                                                chart_data={this.state.distributionData}
                                                                toolTipTitle="Frequency Distribution"
                                                                xValue="value"
                                                                yValue="count"
                                                                isError={false}
                                                                scale={MIN_MAX_SCALE}
                                                            />}
                                                        />
                                                        :
                                                        <BasicPortlet id="dataDistribution"
                                                            video_url="profile_data"
                                                            title="Data Distribution" srcOption={[]}
                                                            content={<div className="text-center text-muted py-5 w-100">
                                                                <h1><FontAwesomeIcon icon={faChartArea} /></h1>
                                                                <h4>Data distribution is not available for the selected attribute name</h4>
                                                                <p>Please check after sometime.</p>
                                                            </div>}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={6}>
                                            {this.state.asterPlotChart !== null && this.state.asterPlotChart.length > 0 ?
                                                <BasicPortlet id="metricsView"
                                                    video_url="profile_data"
                                                    className="mb-3"
                                                    title="Metric View" srcOption={[]}
                                                    content={<ChartBody
                                                        id="test4"
                                                        page="attribute"
                                                        type="Metrics View"
                                                        chartType="barChart"
                                                        style={{ height: "350px" }}
                                                        chart_data={this.state.asterPlotChart}
                                                        toolTipTitle="Metric View"
                                                        xValue="Metric"
                                                        yValue="Metric Value (in Percentage)"
                                                        isError={false}
                                                        colorCode="#915EF9"
                                                        scale={MIN_MAX_SCALE}
                                                    />}
                                                />
                                                :
                                                <BasicPortlet id="metricsView"
                                                    video_url="profile_data"
                                                    title="Metric View" srcOption={[]}
                                                    content={<div className="text-center text-muted py-5 w-100">
                                                        <h1><FontAwesomeIcon icon={faChartArea} /></h1>
                                                        <h4>Metrics View is not available for the selected attribute name</h4>
                                                        <p>Please check after sometime.</p>
                                                    </div>}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    <Row bsPrefix="form-row boxplot">
                                        <Col lg={12}>


                                            {
                                                /**
                                                * Condition details:
                                                * Check for non numberic cloumns
                                                * Can't show statistic view for empty(Null) numberic columns
                                                * Additional check made for NA in numeric columns
                                                * If possible we can modify the NA check.
                                                */
                                                (!NON_NUMERIC_COLUMNS.includes(this.state.dataType)) ?
                                                    <>
                                                        {
                                                            (this.state.statisticsData !== undefined && this.state.statisticsData.statistics[0].latest_quantile.q1_min !== "NA") ?
                                                                <BasicPortlet id="statisticView"
                                                                    video_url="profile_data"
                                                                    title={`Last 10 Days statistics view of ${currAttributeName}`}
                                                                    shortTitle={`${getShortTitleForAttribute(currAttributeName)["requiredName"]}`}
                                                                    titlePrefix={`Last 10 Days statistics view of `}
                                                                    toolTipHandle={FQ_TOOLTIP_PLACEHOLDER}
                                                                    titleTooltip={getShortTitleForAttribute(currAttributeName)["fqTooltipContent"]}
                                                                    srcOption={[]}
                                                                    content={<ChartBody
                                                                        id="test6"
                                                                        chart_data={this.state.statisticsData}
                                                                        chartType="boxPlot"
                                                                        style={{ height: "350px" }}
                                                                    />}
                                                                />
                                                                :
                                                                <BasicPortlet id="statisticView"
                                                                    video_url="profile_data"
                                                                    title={`Last 10 Days statistics view of ${currAttributeName}`}
                                                                    shortTitle={`${getShortTitleForAttribute(currAttributeName)["requiredName"]}`}
                                                                    titlePrefix={`Last 10 Days statistics view of `}
                                                                    toolTipHandle={FQ_TOOLTIP_PLACEHOLDER}
                                                                    titleTooltip={getShortTitleForAttribute(currAttributeName)["fqTooltipContent"]}
                                                                    srcOption={[]}
                                                                    content={<div className="text-center text-muted py-5 w-100">
                                                                        <h1><FontAwesomeIcon icon={faChartArea} /></h1>
                                                                        <h4>Statistics results are not available for the selected attribute name</h4>
                                                                        <p>Please check after sometime.</p>
                                                                    </div>}
                                                                />
                                                        }
                                                    </>
                                                    :
                                                    <BasicPortlet id="statisticView"
                                                        video_url="profile_data"
                                                        title={`Last 10 Days statistics view of ${currAttributeName}`}
                                                        shortTitle={`${getShortTitleForAttribute(currAttributeName)["requiredName"]}`}
                                                        titlePrefix={`Last 10 Days statistics view of `}
                                                        toolTipHandle={FQ_TOOLTIP_PLACEHOLDER}
                                                        titleTooltip={getShortTitleForAttribute(currAttributeName)["fqTooltipContent"]}
                                                        srcOption={[]}
                                                        content={<div className="text-center text-muted py-5 w-100">
                                                            <h1><FontAwesomeIcon icon={faChartArea} /></h1>
                                                            <h4>Statistics results are not applicable for {DATA_TYPE_MAPPING[this.state.dataType] !== undefined ? DATA_TYPE_MAPPING[this.state.dataType] : this.state.dataType} types</h4>
                                                            <p>Choose numeric attribute name to get the statistics results.</p>
                                                        </div>}
                                                    />
                                            }
                                        </Col>
                                    </Row>
                                </>
                                :
                                (
                                    this.state.noDataComponent === true || this.state.noDataComponent === undefined                                    
                                    ?
                                        <>
                                            {
                                                this.state.isFilterMode === true
                                                ?
                                                    this.renderFilter()
                                                :
                                                    ""
                                            }
                                            <div className="col-md-8 offset-md-2 text-center p-5">
                                                <div className="p-5">
                                                <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                                                    <p>{NO_DATASET_NO_ERROR}</p>
                                                    <div className="d-flex justify-content-center">
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    :
                                    <>
                                        {this.chooseLoadingComponent(emptyContentMessage)}
                                    </>
                                )
                        }
                    </>
        );

    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, { setDataSource, setDataSet, setAttribute })(Attribute);