/**
 * Represents the Monitor page -> Model performance -> Compare View tab contents
 * */

import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import Load from '../components/loadAction';
import {
    filterBasedOnMetricNames,
    filterInfoData_mlModel,
    getDefaultDateRangeOptions,
    getGroupedDataListView, getModelOptionsAssociatedWithAB,
    getTimeFilteredData_mlModel,
    filterBasedOnModelAB_ID
} from "../utils/common_utils";
import _ from 'lodash';
import {
    AB_ADVANCE_FILTER_METRIC_NAMES,
    AB_COMPARE_VIEW_TABLE_HEADERS,
    DISPLAY_DATE_FORMAT, IS_NONE_CHECK, MODEL_PERFORMANCE_GROUPS
} from "../utils/constant";
import BasicPortlet from "../components/basicPortlet";
import QualdoDataTable from "../components/bootstrapTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCalendar } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { getDateObject } from "../charts/browser_utils";
import DateRangePicker from "react-bootstrap-daterangepicker";
import MPCompareViewFullScreen from "./MPCompareViewFullScreen";
import ABModelName from "../components/tableCellComponents/abModelName";
import ModelABSummaryList from "./components/modelABSummaryList";
import ModelABThumbnailChartList from "./components/modelABThumbnailChartList";
import ModelErrListFullScreen from "./modelErrListFullScreen";


class MpCompareView extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.changeModelInFullScreen = this.changeModelInFullScreen.bind(this);
        this.getMPCmpViewTableComponents = this.getMPCmpViewTableComponents.bind(this);
        this.getCustomClassForActions = this.getCustomClassForActions.bind(this);
        this.closeFullScreenMode = this.closeFullScreenMode.bind(this);
        this.modelABCompareViewCustomRowClass = this.modelABCompareViewCustomRowClass.bind(this);
        this.changeModelMPCompareViewTable = this.changeModelMPCompareViewTable.bind(this);
        this.showModelErrorPopupInFullscreen = this.showModelErrorPopupInFullscreen.bind(this);
        this.closeModelErrFullScreenMode = this.closeModelErrFullScreenMode.bind(this);
        this.filterInfoData_mlModel = filterInfoData_mlModel.bind(this);
        this.getTimeFilteredData_mlModel = getTimeFilteredData_mlModel.bind(this);
        this.getGroupedDataListView = getGroupedDataListView.bind(this);

        let mpCompareViewTableData = this.props.mpCompareViewTableData;
        if (mpCompareViewTableData === undefined || mpCompareViewTableData === null) {
            mpCompareViewTableData = [];
        }
        
        let modelDataSourceDetails = this.props.modelDataSourceDetails;
        let compareViewDetailedChartData = this.props.mpCompareViewDetailedChartData;

        let currABData = this.props.availableModelABData;
        let mlModelOptions = getModelOptionsAssociatedWithAB(currABData, this.props.mlModelOptions);

        let fullModelInfo = this.props.monitorModule.mlModelDetails
        const propsDataModuleInfo = this.props.dataModule.info;

        let selectedModelABID = null;
        if (!IS_NONE_CHECK.includes(this.props.addOnInfoFromParam)) {
            selectedModelABID = parseInt(this.props.addOnInfoFromParam);

        }

        let currentTableData;
        if (IS_NONE_CHECK.includes(selectedModelABID)) {
            currentTableData = _.cloneDeep(mpCompareViewTableData);
        } else {
            // User has navigated from Configure page by selecting 'Compare' button in Model AB table.
            // So we will show only the required chart
            currentTableData = filterBasedOnModelAB_ID(mpCompareViewTableData, selectedModelABID)
        }
        let startDate = moment().subtract(9, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });
        if (props.monitorModule.performancePreview !== null && props.monitorModule.performancePreview !== undefined && props.monitorModule.performancePreview["filterStartDate"] !== null &&
            props.monitorModule.performancePreview["filterStartDate"] !== undefined) {

            const filterStartDateStr = props.monitorModule.performancePreview["filterStartDate"];
            const filterEndDateStr = props.monitorModule.performancePreview["filterEndDate"];
            const startDateObj = getDateObject(filterStartDateStr);
            const endDateObj = getDateObject(filterEndDateStr);
            startDate = moment(startDateObj).utc().startOf('day');
            endDate = moment(endDateObj).utc().endOf('day');


        }

        this.state = {
            fullScreenClassKey: '',
            selectedModelAB_ID: selectedModelABID,
            modelErrFullScreenClassKey: '',
            isInFullScreenMode: false,
            isModelErrFullscreenMode: false,
            selectedModelIDFromSummary: '',
            propsDataModuleInfo: propsDataModuleInfo,
            completeTableData: mpCompareViewTableData,
            currentTableData: currentTableData,
            fullScreenData: {},
            selectedModelID: null,
            startDateInside: null,
            endDateInside: null,
            modelErrShowMoreData: _.cloneDeep(this.props.modelErrShowMoreData),
            fullModelInfo: fullModelInfo,
            startDate: startDate,
            endDate: endDate,
            tableUpdated: this.props.tableUpdated,
            filterApplied: false,
            timeFilterApplied: false,
            isAdvancedFilterApplied: false,
            modelIDsForFullScreen: [],
            mlModelOptions: mlModelOptions,
            compareViewDetailedChartData: compareViewDetailedChartData,
            modelDataSourceDetails: modelDataSourceDetails,
            lastProcessedTimeOfSelectedModel: null,
            selectedDataSource:-1
        };
    }

    static getDerivedStateFromProps(props, state) {
        let mpCompareViewTableData = props.mpCompareViewTableData;
        if (mpCompareViewTableData === undefined || mpCompareViewTableData === null) {
            mpCompareViewTableData = [];
        }

        let mlModelOptions = props.mlModelOptions;
        let modelDataSourceDetails = props.modelDataSourceDetails;

        let dataChanged = (state.completeTableData.length !== mpCompareViewTableData.length ||
            modelDataSourceDetails !== state.modelDataSourceDetails);

        let currABData = props.availableModelABData;
        let filteredMlModelOptions = getModelOptionsAssociatedWithAB(currABData, mlModelOptions);

        if (!dataChanged && filteredMlModelOptions.length !== state.mlModelOptions.length) {
            dataChanged = true;
        }

        /* Assign the latest updated data from props to the state variable*/
        if (dataChanged) {
            let currentTableData;
            if (IS_NONE_CHECK.includes(state.selectedModelAB_ID)) {
                // No model AB is selected. So we will show all entries in table
                currentTableData = _.cloneDeep(mpCompareViewTableData);
            } else {
                currentTableData = filterBasedOnModelAB_ID(mpCompareViewTableData, state.selectedModelAB_ID)

            }

            return {
                completeTableData: mpCompareViewTableData,
                currentTableData: currentTableData,
                mlModelOptions: filteredMlModelOptions,
                tableUpdated: !state.tableUpdated,
                modelDataSourceDetails: modelDataSourceDetails
            };
        }

        return null;
    }

    changeModelMPCompareViewTable(selectedModel) {
        let selectedModelID = selectedModel.value;

        let filteredRows = [];
        for (let rowData of this.state.completeTableData) {
            // Third row contains associated model ids with the current model AB configuration
            const associatedModelIDs = rowData[2].model_ids;
            if (!associatedModelIDs.includes(selectedModelID)) {
                continue;
            }

            // Apply time filter, is necessary
            filteredRows.push(_.cloneDeep(rowData));
        }
console.log("this.state.completeTableData",this.state.completeTableData);
const integrationId = this.props.dataModule.modelInfoMap[selectedModelID]?.integration_id;        

        this.setState({
            currentTableData: filteredRows,
            selectedModelID: selectedModelID,
            selectedDataSource: integrationId != null ? integrationId : -1  

        });
    }

    getCustomClassForActions() {
        /**
         * This function returns a "function" which will provide the class for <td> entries
         * i.e the cells of the tables
         * */

        return (cell, row, rowIndex, colIndex) => {
            // 4 Represents the Column index of the 'Detailed Charts' column
            // Refer 'AB_COMPARE_VIEW_TABLE_HEADERS' variable from constant.js
            if (colIndex === 4) {
                return "align-middle";
            }

            return "";
        };
    }

    setFullScreen(selectedModelAB_ID, model_ids) {
        let selectedModelABData = this.state.compareViewDetailedChartData[String(selectedModelAB_ID)];

        let requiredFullScreenData = null;
        if (!IS_NONE_CHECK.includes(selectedModelABData)) {
            requiredFullScreenData = selectedModelABData["data"];

            // Perform time filter on the charts
            requiredFullScreenData = filterBasedOnMetricNames(requiredFullScreenData, null,
                this.state.startDate, this.state.endDate)
        }

        if (IS_NONE_CHECK.includes(requiredFullScreenData)) {
            requiredFullScreenData = _.cloneDeep(MODEL_PERFORMANCE_GROUPS);
        }

        this.setState({
            fullScreenClassKey: "show",
            isInFullScreenMode: true,
            modelIDsForFullScreen: model_ids,
            fullScreenData: requiredFullScreenData,
        });
    }

    closeFullScreenMode() {
        this.setState({
            fullScreenClassKey: '',
            isInFullScreenMode: false,
            startDateInside: null,
            endDateInside: null,
            isAdvancedFilterApplied: false,
        });
    }

    closeModelErrFullScreenMode() {
        this.setState({
            modelErrFullScreenClassKey: '',
            isModelErrFullscreenMode: false,
            isAdvancedFilterApplied: false,
        });
    }

    changeModelInFullScreen(selectedModels, selectedMetrics = [], startDate, endDate, useAndFilter) {
        let requiredFullScreenData = null;

        let requiredMetrics = [];

        for (let selectedMetric of selectedMetrics) {
            const currMetricName = selectedMetric.value;
            let mappedMetricName = AB_ADVANCE_FILTER_METRIC_NAMES[currMetricName];
            if (IS_NONE_CHECK.includes(mappedMetricName)) {
                mappedMetricName = currMetricName;
            }

            requiredMetrics.push(mappedMetricName);
        }

        // Get selected model ids
        const selectedModelIDs = [];
        for (let currModel of selectedModels) {
            if (currModel.value === "*") {
                continue;
            }

            selectedModelIDs.push(currModel.value);
        }

        const selectedModelCount = selectedModelIDs.length;
        const matches = [];
        for (let modelAB_Id of Object.keys(this.state.compareViewDetailedChartData)) {
            const modelABData = this.state.compareViewDetailedChartData[String(modelAB_Id)];
            const associatedModelIDs = modelABData.model_ids;
            let canInclude = false;
            if (useAndFilter) {
                const matchCount = selectedModelIDs.filter(x => associatedModelIDs.includes(x)).length;
                canInclude = selectedModelCount === matchCount;
            } else {
                const matchCount = selectedModelIDs.filter(x => associatedModelIDs.includes(x)).length;
                canInclude = matchCount > 0;
            }

            if (!canInclude) {
                continue;
            }

            if (!IS_NONE_CHECK.includes(modelABData)) {
                requiredFullScreenData = modelABData["data"];
            }

            // Perform filter based on metric name
            requiredFullScreenData = filterBasedOnMetricNames(requiredFullScreenData, requiredMetrics,
                startDate, endDate)
            matches.push(requiredFullScreenData);
        }

        if (matches.length === 0) {
            requiredFullScreenData = _.cloneDeep(MODEL_PERFORMANCE_GROUPS);
            delete requiredFullScreenData["Model Evaluation"];
        } else if (matches.length === 1) {
            requiredFullScreenData = matches[0];
        } else {
            // Merge all matched results since user has selected multiple models
            requiredFullScreenData = matches[0];
            for (let index = 1; index < matches.length; index++) {
                let currMatch = matches[index];
                for (let currKey of Object.keys(currMatch)) {
                    const currSection = currMatch[currKey];
                    if (requiredFullScreenData[currKey] === undefined) {
                        requiredFullScreenData[currKey] = currSection
                        continue;
                    }

                    requiredFullScreenData[currKey].data.push(...currSection.data);
                }
            }

        }

        this.setState({
            fullScreenData: requiredFullScreenData,
            timeFilterApplied: true,
            startDateInside: startDate, endDateInside: endDate,
            isAdvancedFilterApplied: !this.state.isAdvancedFilterApplied
        });
    }

    showModelErrorPopupInFullscreen(modelID, lastProcessedTimeOfSelectedModel) {
        this.setState({
            selectedModelIDFromSummary: modelID,
            modelErrFullScreenClassKey: "show",
            isModelErrFullscreenMode: true,
            lastProcessedTimeOfSelectedModel: lastProcessedTimeOfSelectedModel
        });
    }

    modelABCompareViewCustomRowClass(row, rowIndex) {
        const recentTimeInChart = row["Performance Metrics"].recentTimeValueInChart;
        if (IS_NONE_CHECK.includes(recentTimeInChart) || IS_NONE_CHECK.includes(this.state.startDate)) {
            return "";
        }

        if (moment(new Date(recentTimeInChart)).isBefore(this.state.startDate)) {
            return "row-highlight has-above-alert";
        }

        return "";
    }

    getMPCmpViewTableComponents(actionType, headerValue, cell) {
        if (headerValue === "Model Name") {
            return (
                <ABModelName nameComponents={cell} />
            );
        }

        if (headerValue === "Model Summary") {
            return (
                <ModelABSummaryList showModelErrorFS={this.showModelErrorPopupInFullscreen}
                    summaries={cell} />
            );
        }

        if (headerValue === "Performance Metrics") {

            return (
                <ModelABThumbnailChartList key={`model_ab_${cell.key}`}
                    listData={cell.sections}
                    noModelConfigured={false}
                    isModel={true}
                >
                </ModelABThumbnailChartList>
            );
        }

        if (headerValue === "Action") {
            return (
                <div className="show-more monitor-row_show-more"
                    onClick={this.setFullScreen.bind(this, cell.model_ab_id, cell.model_ids)}>
                    <span className="icon">
                        <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                    <p>Detailed Charts</p>
                </div>
            );
        }

        return cell;
    }

    handleTimeFilter(event, picker) {
        const selectedStartDate = picker.startDate;
        let selectedEndDate = picker.endDate;
        let noModelConfigured = false;
        const selectedModelID = this.state.selectedModelID;

        let clonedData = _.cloneDeep(this.state.completeTableData);
        let filteredRows = [];
        for (let rowData of clonedData) {
            let recentTimeValueInChart = null;
            const thumbnailChartColumn = rowData[2];
            const sections = thumbnailChartColumn.sections;
            const associatedModelIDs = thumbnailChartColumn.model_ids;

            if (selectedModelID !== null && !associatedModelIDs.includes(selectedModelID)) {
                // User has selected a model using model drop down. The selected model is not
                // associated with current Model AB row, so we will skip it
                continue;
            }

            for (let section of sections) {
                const charts = section.data;

                let updatedThumbnailCharts = [];

                for (let chartData of charts) {
                    const timeArr = chartData.time;

                    if (timeArr === undefined) {
                        continue;
                    }

                    let filteredArr = timeArr.filter(x => moment(x).isSameOrAfter(selectedStartDate) &&
                        moment(x).isSameOrBefore(selectedEndDate));

                    let filteredLength = filteredArr.length;
                    // If model is not configured on selected time period,
                    if (noModelConfigured) {
                        continue;
                    }

                    // No filtered data matching the chosen time filter
                    if (filteredLength === 0) {
                        // If the chart has data point before selected start date,
                        // we will show the previous data itself, but with a warning in the top of row
                        filteredArr = timeArr.filter(x => moment(x).isSameOrBefore(selectedStartDate));
                        filteredLength = filteredArr.length;
                        if (filteredLength === 0) {
                            continue;
                        }

                        recentTimeValueInChart = filteredArr[filteredLength - 1];
                    }

                    const indexStart = timeArr.indexOf(filteredArr[0]);
                    chartData.time = timeArr.slice(indexStart, indexStart + filteredLength);
                    if (!IS_NONE_CHECK.includes(chartData.line1)) {
                        chartData.line1 = chartData.line1.slice(indexStart, indexStart + filteredLength);
                    }

                    if (!IS_NONE_CHECK.includes(chartData.line2)) {
                        chartData.line2 = chartData.line2.slice(indexStart, indexStart + filteredLength);
                    }

                    if (!IS_NONE_CHECK.includes(chartData.line3)) {
                        chartData.line3 = chartData.line3.slice(indexStart, indexStart + filteredLength);
                    }

                    updatedThumbnailCharts.push(chartData);
                }

                section.data = updatedThumbnailCharts;
            }

            thumbnailChartColumn.recentTimeValueInChart = recentTimeValueInChart;

            filteredRows.push(rowData);
        }

        this.setState({
            currentTableData: filteredRows,
            filterApplied: !this.state.filterApplied,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            timeFilterApplied: true
        });
    }

    render() {
        const ranges = getDefaultDateRangeOptions();

        let tableData = {
            "data": this.state.currentTableData,
            "headers": AB_COMPARE_VIEW_TABLE_HEADERS
        };


        const customKey = `${tableData.data.length}_${this.state.tableUpdated}_${this.state.filterApplied}`;

        const currStartDate = this.state.startDate;
        const currEndDate = this.state.endDate;
        const currentStartDateAsTxt = currStartDate === null ?
            ' ' :
            currStartDate.format(DISPLAY_DATE_FORMAT);

        const currentEndDateAsTxt = currEndDate === null ?
            ' ' :
            currEndDate.format(DISPLAY_DATE_FORMAT);

        const selectedMlModel = this.props.mlModelOptions.find(x =>
            String(x.value) === String(this.state.selectedModelIDFromSummary));

            const selectedDataSource = this.state.selectedDataSource;
            // const hasValid = hasValidNumberOfDatasets(this.state.integrationsMap, selectedDataSource);
            
            let lastProfiledTime;
            if (selectedDataSource === -1) {
                const profilingTimes = Object.values(this.props.dataModule.lastProfilingTime);
                lastProfiledTime = profilingTimes.reduce((latest, current) => {
                    return new Date(current) > new Date(latest) ? current : latest;
                }, profilingTimes[0]); 
            } else {
                lastProfiledTime = this.props.dataModule.lastProfilingTime[selectedDataSource];
            }
    
        return (

            <>
                <div className="qd-tab__content-action">
                    <div className="caption">
                        <div className="alert moniker-alert" role="alert">
                            <p className="mb-0">
                                <strong> {lastProfiledTime === null || lastProfiledTime === undefined ? "--" : `Your data last profiled at ${lastProfiledTime}`}</strong>
                            </p>
                        </div>
                    </div>

                    <div className="actions">
                        <div className="action-left">
                            <Select
                                name="models"
                                filterOption={({ label }, query) => label.includes(query)}
                                id="chooseModelMPCV"
                                options={this.state.mlModelOptions}
                                onChange={this.changeModelMPCompareViewTable}
                                classNamePrefix='form-control'
                                placeholder="Choose Model"
                            />
                        </div>
                        <div className="action-right">
                            <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                startDate={this.state.startDate}
                                onApply={this.handleTimeFilter}
                                endDate={this.state.endDate}
                                ranges={ranges}>
                                <i>
                                    <FontAwesomeIcon icon={faCalendar} />
                                </i>
                                <span className="d-inline-block">
                                    {currentStartDateAsTxt} - {currentEndDateAsTxt}
                                </span>
                            </DateRangePicker>
                        </div>
                    </div>
                </div>

                {this.state.isModelErrFullscreenMode ?
                    <ModelErrListFullScreen fullScreenClassVal={this.state.modelErrFullScreenClassKey}
                        title={"Model Errors - Dashboard"}
                        key={this.state.selectedModelInFullScreen}
                        showModelFilter={true}
                        lastProfilingTimeModelInside={lastProfiledTime}
                        timeFilterApplied={this.state.timeFilterApplied}
                        mlModelOptions={this.state.mlModelOptions}
                        attributeOptions={this.props.attributeOptions}
                        variant={"compareViewFullScreenModelError"}
                        startDate={moment(this.state.startDate.startOf("day"))}
                        endDate={moment(this.state.endDate).endOf("day")}
                        changeModelInFullScreen={this.changeModelInFullScreen}
                        closeFullScreen={this.closeModelErrFullScreenMode}
                        isAdvancedFilterApplied={`mp_ab_cv_${this.state.isAdvancedFilterApplied}`}
                        selectedMlModel={selectedMlModel}
                        modalData={this.state.modelErrShowMoreData}
                    >
                    </ModelErrListFullScreen> : ''
                }
                {this.state.isInFullScreenMode ?
                    <MPCompareViewFullScreen fullScreenClassVal={this.state.fullScreenClassKey}
                        key={currentStartDateAsTxt}
                        title={"Model Performance Metrics - Dashboard"}
                        showModelFilter={true}
                        timeFilterApplied={this.state.timeFilterApplied}
                        fullScreenData={this.state.fullScreenData}
                        mlModelOptions={this.state.mlModelOptions}
                        selectedMlModel={this.state.mlModelOptions.filter(x => this.state.modelIDsForFullScreen.includes(x.value))}
                        variant={"mlModelPerformanceCompareView"}
                        changeModelInFullScreen={this.changeModelInFullScreen}
                        closeFullScreen={this.closeFullScreenMode}
                        isAdvancedFilterApplied={this.state.isAdvancedFilterApplied}
                        startDate={moment(this.state.startDate.startOf("day"))}
                        startDateInside={this.state.startDateInside}
                        endDateInside={this.state.endDateInside}
                        endDate={moment(this.state.endDate).endOf("day")}

                    >

                    </MPCompareViewFullScreen> :

                    this.state.isModelErrFullscreenMode ? '' :
                        <BasicPortlet
                            key={`monitorMPCmpView_${customKey}`}
                            className="pb-0"
                            id="monitorMPCmpView"
                            title="Configured models for AB Testing"
                            content={
                                this.props.showLoader === true && this.props.firstDatasetUp === false ?
                                    <Load />
                                    :
                                    <QualdoDataTable
                                        id="monitorMPCmpView"
                                        key={`M_MPCV_table_${customKey}`}
                                        customGetActionComponent={this.getMPCmpViewTableComponents}
                                        data={tableData}
                                        component_name="mpCompareViewTable"
                                        paginationSize={5}
                                        customRowHighlighter={this.modelABCompareViewCustomRowClass}
                                        getCustomClassForColumns={this.getCustomClassForActions()}
                                    />
                            }
                        />
                }

            </>
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null)(MpCompareView);